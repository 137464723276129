import Vue from "vue";
import "./components";
import "./app.css";

import "lazysizes/plugins/object-fit/ls.object-fit";
import "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data() {
    return {
      activeNav: false
    };
  }
});
