<template>
  <div
    @mouseover="pause = true"
    @mouseleave="pause = false"
    class="slider relative w-full overflow-hidden"
    :class="{ left }"
  >
    <div
      v-for="i in length"
      :key="i"
      :class="getClass(i)"
      ref="slide"
      class="absolute pin m-auto max-w-6xl hidden cursor-pointer"
      @click="i === prevIndex ? prev() : next()"
    >
      <slot :name="'slide-' + i"></slot>
    </div>
    <!--<span class="pagination">
      <button v-for="i in length" :key="i" @click="goto(i)" :class="{active: active === i}"></button>
    </span>
    <span
      class="absolute z-10 pin-x pin-b h-05 bg-red origin-l ts-250 ts-linear"
      :style="{transform: 'scaleX(' + time / interval + ')'}"
    ></span>-->
  </div>
</template>
<script>
export default {
  props: {
    interval: Number,
    length: Number
  },
  data() {
    return {
      active: 1,
      left: false,
      pause: false,
      time: 0,
      timeout: false
    };
  },
  mounted() {
    setInterval(() => {
      if (!this.pause) {
        this.time += 100;
        if (this.time >= this.interval) {
          this.next();
        }
      }
    }, 100);
    document.addEventListener("keydown", e => {
      if (e.keyCode === 39) {
        this.next();
      } else if (e.keyCode === 37) {
        this.prev();
      }
    });
  },
  computed: {
    nextIndex() {
      return this.getNext(this.active);
    },
    prevIndex() {
      return this.getPrev(this.active);
    }
  },
  methods: {
    getNext(i) {
      return i + 1 > this.length ? 1 : i + 1;
    },
    getPrev(i) {
      return i - 1 === 0 ? this.length : i - 1;
    },
    getClass(i) {
      const prev = this.getPrev(this.prevIndex);
      const next = this.getNext(this.nextIndex);
      return i === this.active
        ? "active"
        : i === this.nextIndex
        ? "next"
        : i === this.prevIndex
        ? "prev"
        : i === prev
        ? "prevprev"
        : i === next
        ? "nextnext"
        : "";
    },
    next() {
      if (this.timeout) return false;
      this.left = false;
      this.time = 0;
      this.active = this.nextIndex;
      this.cooldown();
    },
    prev() {
      if (this.timeout) return false;
      this.left = true;
      this.time = 0;
      this.active = this.prevIndex;
      this.cooldown();
    },
    goto(index) {
      if (this.timeout) return false;
      this.left = index < this.active;
      this.time = 0;
      this.active = index;
      this.cooldown();
    },
    video() {
      this.$refs.slide.forEach((slide, index) => {
        const video = slide.querySelector("video");
        if (video) {
          if (index === this.active - 1) {
            video.muted = false;
            video.volume = 1;
            video.play();
          } else video.pause();
        }
      });
    },
    cooldown() {
      this.video();
      this.timeout = true;
      setTimeout(() => {
        this.timeout = false;
      }, 500);
    }
  }
};
</script>
<style scoped>
.slider {
  &.left {
    .nextnext {
      transform: translateX(-200%) scale(0.8);
    }
    .prevprev {
      transform: translateX(200%) scale(0.8);
    }
    .next {
      transform: translateX(-95%) scale(0.8);
    }
    .prev {
      transform: translateX(95%) scale(0.8);
    }
    .active {
      transform: none;
    }
  }
}

.active,
.next,
.prev,
.nextnext,
.prevprev {
  display: block;
  transition: transform 0.6s ease-out, opacity 0.5s ease-in-out;
}

.next,
.prev,
.nextnext,
.prevprev {
  opacity: 0.5;
}

.nextnext {
  transform: translateX(200%) scale(0.8);
}
.prevprev {
  transform: translateX(-200%) scale(0.8);
}

.active {
  transform: none;
  position: relative;
  margin-bottom: 0;
  z-index: 1;
}

.next {
  transform: translateX(95%) scale(0.8);
}
.prev {
  transform: translateX(-95%) scale(0.8);
}
</style>
